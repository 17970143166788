<template>
	<article class="analytic-tenants-article">
		<v-expansion-panels accordion multiple>
			<AnalyticPanel
				:items="[]"
				footer="Количество жителей"
				class="mb-4"
				:footerItems="cTenantsFooterItems"
			>
				<template v-slot:header>
					<Linebar
						name="Кол-во пользователей по выбранным адресам"
						:value="cTotalResidentsAddress.value"
						:percent="cTotalResidentsAddress.percent"
					/>
				</template>
			</AnalyticPanel>

			<AnalyticPanel :items="cResidentItems.slice(1)" class="mt-4">
				<template v-slot:header>
					<Linebar
						name="Собственники"
						:value="cResidentItems[0].value"
						:percent="cResidentItems[0].percent"
					/>
				</template>
			</AnalyticPanel>

			<AnalyticPanel :items="[]" class="mt-4">
				<template v-slot:header>
					<Linebar
						name="Арендаторы"
						:value="cArendator.value"
						:percent="cArendator.percent"
					/>
				</template>
			</AnalyticPanel>

			<AnalyticPanel :items="[]" class="mt-4">
				<template v-slot:header>
					<Linebar
						name="Доверенные лица"
						:value="cTrusted.value"
						:percent="cTrusted.percent"
					/>
				</template>
			</AnalyticPanel>
		</v-expansion-panels>
	</article>
</template>

<script>
import AnalyticPanel from "@/components/AnalyticPanel";
import Linebar from "@/components/app/Linebar";
import { mapGetters } from "vuex";

export default {
	name: "AnalyticTenantsArticle",

	components: {
		AnalyticPanel,
		Linebar,
	},

	data() {
		return {
			residentItems: [
				{ name: "Собственники НП" },
				{ name: "Собственники квартир" },
				{ name: "Собственники паркомест" },
			],
		};
	},

	computed: {
		...mapGetters({
			analytic: "ksk/GET_ANALYTIC",
		}),

		cTotalResidentsAddress() {
			return this.analytic?.resident[3] || { value: 0, percent: 0 };
		},

		cTenantsFooterItems() {
			const value = this.analytic?.resident[3].value || 0;
			return [{ label: "жителей", value }];
		},

		cArendator() {
			return this.analytic?.resident[2] || { value: 0, percent: 0 };
		},

		cTrusted() {
			return this.analytic?.resident[2] || { value: 0, percent: 0 };
		},

		cResidentItems() {
			if (this.analytic?.resident) {
				const { value, percent } = this.analytic.resident[0];
				return [
					{ name: "Собственники", value, percent },
					...this.residentItems,
				];
			}
			return this.residentItems;
		},
	},

	created() {
		this.$store.dispatch("ksk/change_analytic_type", "resident");
	},

	mounted() {
		this.$store.dispatch("ksk/load_analytic");
	},
};
</script>

<style></style>
