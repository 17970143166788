<template>
	<section class="analytic-report analytic-report-grid">
		<AnalyticTenantsArticle />
		<AnalyticSidebar :show-paid="false" />
	</section>
</template>

<script>
import AnalyticTenantsArticle from "@/components/AnalyticTenantsArticle";
import AnalyticSidebar from "@/components/AnalyticSidebar";

export default {
	name: "AnalyticReportTenants",

	components: {
		AnalyticTenantsArticle,
		AnalyticSidebar,
	},
};
</script>

<style></style>
